export const formatTotalCoin = (num) => {
  let dollarUSLocale = Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 8,
  });

  return dollarUSLocale.format(num);
};

export const getTime = (now) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return `${
    monthNames[now.getMonth()]
  } ${now.getDate()}, ${now.getFullYear()}, ${now.getHours()}:${
    now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
  } UTC`;
};
