import React, { useState } from "react";
// import sections
import Hero from "../components/sections/Hero";
import "../assets/scss/landingPage.scoped.scss";
import ItemStaking from "../components/sections/ItemStaking";
import ModalHistory from "../components/sections/ModalDeposit";
import {
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
  PACKAGE_6_MONTHS,
} from "../../../config";

import GuideDetail from "../components/sections/GuideDetail";

const Home = ({
  balance,
  isApproveToTransfer,
  onApprove,
  onStake,
  historyByPackage,
  onUnBlock,
  onHarvest,
}) => {
  const [openDeposit, setOpenDeposit] = useState(false);
  let selectedPackage = React.useRef(1);

  const toggleDeposit = () => setOpenDeposit(!openDeposit);

  const setPackageDeposit = async (type) => {
    if (!isApproveToTransfer) {
      await onApprove();
    }

    selectedPackage.current = type;
    toggleDeposit();
  };

  const handleStaking = async (coin) => {
    await onStake(selectedPackage.current, coin);
    toggleDeposit();
  };

  const handleApprove = async (type) => {
    selectedPackage.current = type;
    const isApproveSuccess = await onApprove();
    if (isApproveSuccess) {
      toggleDeposit();
    }
  };

  return (
    <>
      <Hero />
      <div
        className="container ui doubling stackable three column grid"
        id="main-page"
      >
        <ItemStaking
          title="3 Months"
          package={PACKAGE_3_MONTHS}
          apr={5}
          packageId={1}
          history={historyByPackage[PACKAGE_3_MONTHS]}
          toggleDeposit={() => setPackageDeposit(PACKAGE_3_MONTHS)}
          onUnBlock={onUnBlock}
          onHarvest={onHarvest}
          isApproveToTransfer={isApproveToTransfer}
          onApprove={() => handleApprove(PACKAGE_3_MONTHS)}
          isFinished
        />

        <ItemStaking
          title="6 Months"
          package={PACKAGE_6_MONTHS}
          history={historyByPackage[PACKAGE_6_MONTHS]}
          apr={15}
          packageId={2}
          toggleDeposit={() => setPackageDeposit(PACKAGE_6_MONTHS)}
          onUnBlock={onUnBlock}
          onHarvest={onHarvest}
          isApproveToTransfer={isApproveToTransfer}
          onApprove={() => handleApprove(PACKAGE_6_MONTHS)}
          isFinished
        />
        <ItemStaking
          isCenter={true}
          package={PACKAGE_12_MONTHS}
          history={historyByPackage[PACKAGE_12_MONTHS]}
          title="12 Months"
          apr={10}
          packageId={3}
          toggleDeposit={() => setPackageDeposit(PACKAGE_12_MONTHS)}
          onUnBlock={onUnBlock}
          onHarvest={onHarvest}
          isApproveToTransfer={isApproveToTransfer}
          onApprove={() => handleApprove(PACKAGE_12_MONTHS)}
          isFinished
        />
      </div>
      <div
        className="container"
        style={{ position: "relative", textAlign: "center", marginTop: 20 }}
      >
        <h1
          className="mt-0 mb-16 reveal-from-bottom text-primary text-white"
          data-reveal-delay="200"
        >
          UNLOCKING RATE & TERM{" "}
        </h1>
        <GuideDetail />
      </div>
      <ModalHistory
        open={openDeposit}
        toggleModal={toggleDeposit}
        selectedPackage={selectedPackage.current}
        balance={balance}
        onSubmit={handleStaking}
      />
    </>
  );
};

export default Home;
