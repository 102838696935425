import React from "react";
// import sections
import "../assets/scss/landingPage.scoped.scss";
import guideA from "../assets/images/guide/guide-1.png";
import guideB from "../assets/images/guide/guide-2.png";
import guideC from "../assets/images/guide/guide-3.png";
import guideD from "../assets/images/guide/guide-4.png";
import guideF from "../assets/images/guide/guide-6.png";
import guideJ from "../assets/images/guide/guide-7.png";
import ThankYou from "../assets/images/guide/guide-8.png";

const Guide = () => {
  return (
    <>
      <img alt="giuid A" className="first-image-guide" src={guideA} />
      <img alt="giuid b" src={guideB} />
      <img alt="giuid c" src={guideC} />
      <img alt="giuid d" src={guideD} />
      <img alt="giuid f" src={guideF} />
      <img alt="giuid J" src={guideJ} />
      <img alt="thankyou" src={ThankYou} />
    </>
  );
};

export default Guide;
