import React, { useContext } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { handleChangeNetwork } from "../../../../utils/metamask";
import AppContext from "../../../../appContext";
import "../../assets/scss/landingPage.scoped.scss";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const { networkId } = useContext(AppContext);

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            {/* {networkId.toString() !== process.env.REACT_APP_NETWORK_ID.toString() && (
              <span style={{ color: "white" }}>
                Please connect BSC testnet network!
                <div onClick={handleChangeNetwork} className="switch-container">
                  <img
                    src="https://market.creaturehunters.world/static/media/btn-switch.cf020bb3.png"
                    className="switch-icon"
                    alt="guide-board-bg"
                  />
                  <span className="switch-txt">SWITCH</span>
                </div>
              </span>
            )} */}

            <h1
              className="mt-0 mb-16 reveal-from-bottom text-white text-primary"
              data-reveal-delay="200"
            >
              Stake To Earn - Effortless Gaining Rewards & CHTS{" "}
            </h1>
            {/* <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom section-subtitle text-white"
                data-reveal-delay="400"
              >
                <b>
                  Stake CHTS tokens to more new CHTS token, high APR and low
                  risk
                </b>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
