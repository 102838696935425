import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import ScrollReveal from "./utils/ScrollReveal";
import AppContext from "../../appContext";
import "../landingPage/assets/scss/landingPage.scoped.scss";
import * as Sentry from "@sentry/react";
import {
  approveCHTSTokensToTransfer,
  convertToTokens,
  checkCHTSTokensFor,
  // getInfoPackage,
  stakeToFarm,
  getHistoryStake,
  getCurrentClaimInfo,
  claimProfit,
  claimStake,
} from "../../utils/assets";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import { toast } from "react-toastify";

import notifyBG from "../landingPage/assets/images/bg-notify.svg";
import iconSuccess from "../landingPage/assets/images/icon-success.svg";

// Views
import Home from "./views/Home";

import {
  PACKAGE_6_MONTHS,
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
} from "../../config";

import ModalSetting from "./components/sections/ModalSetting";

const LandingPage = () => {
  const {
    web3,
    account,
    handleBlockScreen,
    screenBlocked,
    openSetting,
    toggleSetting,
  } = useContext(AppContext);
  const childRef = useRef();
  let location = useLocation();

  const Layout =
    LayoutDefault === undefined
      ? (props) => <>{props.children}</>
      : LayoutDefault;

  const [CHTSToken, setCHTSToken] = useState(0);

  const [isApproveToTransfer, setApprove] = useState(0);
  const [historyByPackage, setHistory] = useState({
    [PACKAGE_6_MONTHS]: [],
    [PACKAGE_12_MONTHS]: [],
    [PACKAGE_3_MONTHS]: [],
  });

  const notify = (message, isFailed = false) =>
    toast(
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 5,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* insert your icon here */}
        <img
          src={notifyBG}
          style={{ position: "absolute", zIndex: -1 }}
          alt="notifybg"
        />
        <div className="notify-inner">
          <span
            className={isFailed ? "notify-failed-text" : "notify-success-text"}
            style={{ display: "flex" }}
          >
            <img
              src={iconSuccess}
              alt="notifysuccess"
              style={{
                width: 20,
                height: 20,
                marginRight: 15,
                display: isFailed ? "none" : "inline-block",
              }}
            />
            {message}
          </span>
          <div className="icon-close-notify"> X</div>
        </div>
      </div>,
      { position: toast.POSITION.TOP_CENTER, autoClose: 3500 }
    );

  const handleTokensChange = useCallback(async () => {
    handleBlockScreen(true);
    try {
      const { balance, isApprove } = await checkCHTSTokensFor(web3, account);

      function floorFigure(figure, decimals) {
        if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return Number((parseInt(figure * d) / d).toFixed(decimals));
      }

      setCHTSToken(floorFigure(+convertToTokens(balance, web3), 3));
      setApprove(isApprove);

      // const { packageOne, packageTwo, packageThree } = await getInfoPackage(
      //   web3
      // );

      const history = await getHistoryStake(web3, account);

      const { resultProfit, resultStake } = await getCurrentClaimInfo(
        web3,
        Object.keys(history)
      );

      Object.keys(resultProfit).forEach((key) => {
        history[key].profitCanClaim =
          resultProfit[key] - history[key].profitClaimed;
      });

      Object.keys(resultStake).forEach((key) => {
        history[key].stakeCanClaim =
          resultStake[key] - history[key].stakeClaimed;
      });

      const newHistoryByPackage = {
        [PACKAGE_6_MONTHS]: [],
        [PACKAGE_12_MONTHS]: [],
        [PACKAGE_3_MONTHS]: [],
      };

      Object.keys(history).forEach((profileId) => {
        const packageId = history[profileId].packageId;
        newHistoryByPackage[packageId].push(history[profileId]);
      });

      setHistory(newHistoryByPackage);
    } catch (e) {}

    handleBlockScreen(false);
  }, [account, web3]);

  const handleHistory = async () => {
    if (screenBlocked) {
      return;
    }

    try {
      const history = await getHistoryStake(web3, account);

      const { resultProfit, resultStake } = await getCurrentClaimInfo(
        web3,
        Object.keys(history)
      );

      Object.keys(resultProfit).forEach((key) => {
        history[key].profitCanClaim =
          resultProfit[key] - history[key].profitClaimed;
      });

      Object.keys(resultStake).forEach((key) => {
        history[key].stakeCanClaim =
          resultStake[key] - history[key].stakeClaimed;
      });

      const newHistoryByPackage = {
        [PACKAGE_6_MONTHS]: [],
        [PACKAGE_12_MONTHS]: [],
        [PACKAGE_3_MONTHS]: [],
      };

      Object.keys(history).forEach((profileId) => {
        const packageId = history[profileId].packageId;
        newHistoryByPackage[packageId].push(history[profileId]);
      });

      setHistory(newHistoryByPackage);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      //it could happen that account is empty when this useEffect runs initially, hence the guard
      if (account) {
        handleTokensChange();
      } else {
        setHistory({});
      }
    })();
  }, [account, handleTokensChange]);

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let intervalId = useRef(0);

  useEffect(() => {
    if (account) {
      intervalId.current = setInterval(handleHistory, 10000);
    } else {
      clearInterval(intervalId.current);
    }

    return () => clearInterval(intervalId.current);
  }, [web3, account]);

  const onApproveToTransfer = useCallback(async () => {
    try {
      handleBlockScreen(true);
      await approveCHTSTokensToTransfer(web3, account);
      setApprove(true);
      handleBlockScreen(false);
    } catch (error) {
      Sentry.captureException({
        message: error.message,
        address: account,
        type: "onApproveToTransfer",
      });
    }
  }, [web3, account]);

  const onStake = useCallback(
    async (type, numberCoin) => {
      handleBlockScreen(true);
      try {
        await stakeToFarm(web3, account, numberCoin, type);
        const { balance } = await checkCHTSTokensFor(web3, account);
        setCHTSToken(+convertToTokens(balance, web3));
        await handleTokensChange();
        // notify("Staking success");
      } catch (e) {
        notify("Staking failed", true);

        Sentry.captureException({
          message: e.message,
          address: account,
          type: "onStake",
          numberCoin,
          type: "onStake",
          package: type,
        });

        handleBlockScreen(false);
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onHarvest = useCallback(
    async (profileId, profit) => {
      handleBlockScreen(true);
      try {
        try {
          await claimProfit(web3, account, profileId);
          // await onStake(profileId, profit)
          await handleTokensChange();
          notify("Harvest success");
        } catch (e) {}
      } catch (e) {
        notify("Harvest failed", true);
        Sentry.captureException({
          message: e.message,
          address: account,
          type: "onHarvest",
          packageId: profileId,
        });
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onUnBlock = useCallback(
    async (profileId, profit) => {
      handleBlockScreen(true);
      try {
        await claimStake(web3, account, profileId);
        await handleTokensChange();
        // await onStake(3, profit);
        // notify("Unlock success");
      } catch (e) {
        notify("Unblock failed");
        Sentry.captureException({
          message: e.message,
          address: account,
          packageId: profileId,
          type: "onUnBlock",
        });
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Layout>
            <Home
              balance={CHTSToken}
              isApproveToTransfer={isApproveToTransfer}
              onApprove={onApproveToTransfer}
              onStake={onStake}
              historyByPackage={historyByPackage}
              onHarvest={onHarvest}
              onUnBlock={onUnBlock}
            />
            <ModalSetting
              open={openSetting}
              balance={CHTSToken}
              account={account}
              toggleModal={toggleSetting}
            />
          </Layout>
        )}
      />
    </>
  );
};

export default LandingPage;
