import React from "react";

import Guide from "./views/Guide";
import LayoutDefault from "./layouts/LayoutDefault";
import ScrollReveal from "./utils/ScrollReveal";

const GuidePage = () => {
  const Layout =
    LayoutDefault === undefined
      ? (props) => <>{props.children}</>
      : LayoutDefault;

  return (
    <ScrollReveal
      children={() => (
        <Layout>
          <Guide />
        </Layout>
      )}
    />
  );
};

export default GuidePage;
