import React, { useState, useContext } from "react";
import { Image, Card, Button, Popup } from "semantic-ui-react";

import ItemHistory from "./ItemHistory";

import cardImg from "../../assets/images/card.png";
import finishIcon from "../../assets/images/finish.png";

import bnbIcon from "../../assets/images/coin-swap.png";
import roiIcon from "../../assets/images/icon-roi.svg";
import arrDownIcon from "../../assets/images/arrow-down.svg";
import AppContext from "../../../../appContext";
import { handleConnect } from "../../../../utils/metamask";

import { isMobile } from "react-device-detect";

import { PACKAGE_PROFIT, PACKAGE_TITLE } from "../../../../config";

function ItemStaking({
  toggleDeposit,
  history = [],
  onUnBlock,
  onHarvest,
  packageId = 3,
  isApproveToTransfer,
  onApprove,
  isFinished = false,
}) {
  const { account, networkId } = useContext(AppContext);

  const [isOpenHistory, setOpenHistory] = useState(true);

  const toggleHistory = () => setOpenHistory(!isOpenHistory);

  const isDisabledButton = networkId != process.env.REACT_APP_NETWORK_ID;

  return (
    <div className={`container-stake-card`}>
      <Card
        className="stake-card"
        style={{
          backgroundImage: `url(${cardImg})`,
        }}
      >
        <div
          className={`main-card ${isOpenHistory ? "main-margin-bottom" : ""}`}
        >
          <div className="icon-swap">
            <Image src={bnbIcon} className="icon-coin" />
          </div>
          <p className="title" style={{ color: "#7BE7F2" }}>
            CHTS
          </p>
          <p className="subtitle">Stake CHTS to earn CHTS</p>

          <div className="flex-row">
            <span className="text-semi-bold text-white">
              {`${PACKAGE_TITLE[packageId].toUpperCase()}`}
            </span>
            <span className="text-bold apr">
              {`${PACKAGE_PROFIT[packageId].earningRate}%`}
              <Popup
                style={{ cursor: "pointer" }}
                // content={`- Profits can be withdrawn at any time\n- Staking Rate/Month : ${PACKAGE_PROFIT[packageId].ratePerMonth}%`}
                trigger={<Image src={roiIcon} className="icon-roi" />}
              >
                <Popup.Content>
                  <span className="medium-text">
                    {`-Rate : `}
                    <span className="hover-roi">{`${PACKAGE_PROFIT[packageId].ratePerMonth}%/Month`}</span>
                  </span>
                  <br />

                  <span className="medium-text">
                    -Profits can be withdrawn at any time
                  </span>
                </Popup.Content>
              </Popup>
            </span>
          </div>
          <div className="btn-container">
            {!isFinished ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: 30, height: 30, marginRight: 10 }}
                  src={finishIcon}
                />
                <p style={{ fontWeight: "bold", color: "#ffaa00" }}>Finished</p>
              </div>
            ) : isMobile && !account && !window.ethereum ? (
              <a
                href={process.env.REACT_APP_DEEPLINK_METAMASK}
                className="btn-stake medium-text"
                onClick={handleConnect}
              >
                CONNECT METAMASK
              </a>
            ) : !isApproveToTransfer ? (
              <Button
                className={`btn-stake medium-text ${
                  isDisabledButton ? "btn-disabled" : ""
                }`}
                onClick={account ? onApprove : handleConnect}
                disabled={isDisabledButton}
              >
                {account ? "ENABLE" : "CONNECT WALLET"}
              </Button>
            ) : (
              <Button
                className={`btn-stake medium-text ${
                  isDisabledButton ? "btn-disabled" : ""
                }`}
                onClick={account ? toggleDeposit : handleConnect}
                disabled={isDisabledButton}
              >
                {account ? "STAKE CHTS" : "CONNECT WALLET"}
              </Button>
            )}
          </div>
          <div className="guide">
            <p className="guide-title">{`${PACKAGE_TITLE[packageId]} Staking`}</p>
            <p className="guide-content">{`Earning Rate : ${PACKAGE_PROFIT[packageId].earningRate}%`}</p>
            <p className="guide-content">
              {`Staking Rate/Month : ${PACKAGE_PROFIT[packageId].ratePerMonth}%`}
            </p>
            <p className="guide-content" style={{ minHeight: 50 }}>
              {packageId != 3
                ? ` *Cancel The Lockup After ${PACKAGE_TITLE[packageId]} Of Staking (Principal After For
              ${PACKAGE_PROFIT[packageId].timeLock} Month)`
                : ` *Cancel The Lockup After ${PACKAGE_TITLE[packageId]} Of Staking`}
            </p>
          </div>

          <div
            className={`btn-detail ${history.length ? "" : "display-none"}`}
            onClick={toggleHistory}
          >
            <span style={{ fontSize: 14 }}>Detail</span>
            <Image src={arrDownIcon} className="arrow-down" />
          </div>
          <div className={`tab-history ${isOpenHistory ? "" : "display-none"}`}>
            {history.map((item) => (
              <ItemHistory
                key={item.id.toString()}
                data={item}
                onUnBlock={onUnBlock}
                onHarvest={onHarvest}
              />
            ))}
            <div className="tab-history-footer">
              <a
                rel="noopener noreferrer"
                className="view-bsc"
                target="_blank"
                href={`${process.env.REACT_APP_LINK_BSC}/${process.env.REACT_APP_TOKEN_FARM_ADDRESS}`}
              >
                View on BSCscan
              </a>
              <div
                className={`btn-detail ${history.length ? "" : "display-none"}`}
                onClick={toggleHistory}
              >
                <span>Show less</span>
                <Image src={arrDownIcon} className="arrow-down arrow-up" />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ItemStaking;
