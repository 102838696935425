import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Image } from "semantic-ui-react";
import cardImg from "../../assets/images/bg-modal-1.png";
import closeImg from "../../assets/images/icon-close.svg";
import AppContext from "../../../../appContext";
import { PACKAGE_TITLE } from "../../../../config";

function ModalDeposit({
  open,
  toggleModal,
  onSubmit,
  balance = 1000,
  selectedPackage,
}) {
  const [error, setError] = useState("");
  const [numberCoin, setCoin] = useState(0);

  const { screenBlocked } = useContext(AppContext);

  useEffect(() => {
    if (!open) {
      setCoin(0);
    } else {
      setCoin(balance);
    }
  }, [open]);

  const onInput = (e) => {
    if (e.target.value > balance) {
      return setError("You have not enough CHTS !!!");
    }

    setCoin(+e.target.value);
    if (error) {
      setError("");
    }
  };

  const submit = async () => {
    await onSubmit(numberCoin);
  };

  const onClickMax = () => setCoin(balance);

  return (
    <Modal
      onClose={toggleModal}
      open={open && !screenBlocked}
      id="modal-deposit"
    >
      <div className="inner-modal">
        <Image src={cardImg} className="bg-modal" />

        <div className="modal-header">
          <p className="text-deposite">Deposit Token</p>
          <div onClick={toggleModal} className="close-container">
            <Image src={closeImg} className="icon-close" />
          </div>
        </div>

        <div className="modal-content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="modal-balance">{`${PACKAGE_TITLE[selectedPackage]}`}</p>

            <p className="modal-balance">{`Balance: ${balance}`}</p>
          </div>
          <div className="input-container">
            <input
              className="input-field-deposit"
              // type="number"
              onChange={onInput}
              value={numberCoin.toString().replace(/,/g, '.')}
              step="any"
              disabled
            />
            <div className="input-info">
              {/* <span className="btn-max">
                Max
              </span> */}
              <span>CHTS</span>
            </div>
          </div>
          <p className="modal-error">{error}</p>
        </div>
        <p style={{color: 'white', fontSize: 14}}>Only accept upto 3 decimal places, ex: 100.456</p>

        <div className="modal-footer">
          <Button className="btn-modal-outline" onClick={toggleModal}>
            CANCEL
          </Button>
          <Button
            className="btn-modal-shape"
            disabled={!!error || !numberCoin}
            onClick={submit}
          >
            CONFIRM
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalDeposit;
