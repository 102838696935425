import React from "react";
import { Button, Card, Image } from "semantic-ui-react";

import chtsIcon from "../../assets/images/icon-chts.png";

import { formatTotalCoin, getTime } from "../../../../utils/helper";

const DATA_BY_MONTH = {
  1: 3,
  2: 6,
  3: 12,
};

const DATA_INTEREST_BY_MONTH = {
  1: 2,
  2: 4,
  3: 5,
};

const DATA_INTEREST = {
  1: 6,
  2: 24,
  3: 60,
};

function ItemHistory({ data, onUnBlock, onHarvest, isLast = false }) {
  const handleUnBlock = () =>
    onUnBlock(data.id, data?.stakeCanClaim + data?.profitCanClaim);
  const handleHarvest = () => onHarvest(data.id, data?.profitCanClaim);

  const totalEarnedValue = formatTotalCoin(
    data.profitClaimed + data.stakeClaimed
  );
  const totalStakedValue = formatTotalCoin(data.amount);
  const stakeCanClaim = formatTotalCoin(data?.stakeCanClaim);
  const profitCanClaim = formatTotalCoin(data?.profitCanClaim);
  const monthlyInterest = formatTotalCoin(
    (data.amount * DATA_INTEREST_BY_MONTH[data.packageId]) / 100
  );

  const totalEarnValue = formatTotalCoin(
    (data.amount * DATA_INTEREST[data.packageId]) / 100
  );

  const disableHarvest = !data.profitCanClaim;
  const disableUnBlock = !data.stakeCanClaim;

  const getDiffDay = () => {
    const date1 = new Date(data.vestingStart * 1000);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const acceptWithdraw = () => {
    const divideProfit = data.totalProfit / DATA_BY_MONTH[data.packageId];
    if (
      (data?.profitCanClaim > divideProfit && getDiffDay() >= 30) ||
      getDiffDay() >= DATA_BY_MONTH[data.packageId] * 30
    ) {
      return true;
    }
    return false;
  };

  return (
    <Card className={`history-card ${isLast ? "" : "border-bottom"}`}>
      <div className="flex-row margin-y-5" style={{ justifyContent: "center" }}>
        <span className="flex justify-between" style={{ gap: "5px" }}>
          <Button
            className="btn-stake btn-mini"
            id="btn-harvest"
            onClick={handleHarvest}
            disabled={disableHarvest || !acceptWithdraw()}
            // data-tooltip={
            //   disableHarvest ? "You have no profit to harvest now !!!" : null
            // }
          >
            Harvest Profit
          </Button>

          <Button
            id="btn-unblock"
            className="btn-stake btn-mini"
            onClick={handleUnBlock}
            disabled={disableUnBlock}
            // data-tooltip={disableUnBlock ? "No value to unblock." : null}
          >
            Unblock
          </Button>
        </span>
      </div>

      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Total Staked Value:</p>
        <p className="margin-y-2 medium-text">{totalStakedValue}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Total Claimed Value:</p>
        <p className="margin-y-2 medium-text">{totalEarnedValue}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">{`Total Earn Value (${
          DATA_INTEREST[data.packageId]
        }%):`}</p>
        <p className="margin-y-2 medium-text">{totalEarnValue}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">{`Monthly Interest (${
          DATA_INTEREST_BY_MONTH[data.packageId]
        }%):`}</p>
        <p className="margin-y-2 medium-text">{monthlyInterest}</p>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Stake Unlock:</p>
        <span className="history-staked-value margin-y-2 flex">
          <Image src={chtsIcon} className="icon-chts" />
          {stakeCanClaim}
        </span>
      </div>
      <div className="flex-row margin-y-2">
        <p className="margin-y-2 small-text">Earned Value:</p>
        <span className="history-staked-value margin-y-2 flex">
          <Image src={chtsIcon} className="icon-chts" />
          {profitCanClaim}
        </span>
      </div>
      <div className="flex-row margin-y-2 justify-center">
        <p className="history-time">
          {getTime(new Date(+data.vestingStart * 1000))}
        </p>
      </div>
    </Card>
  );
}

export default ItemHistory;
