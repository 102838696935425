import React from "react";
import { Grid } from "semantic-ui-react";

import bgImage from "../../assets/images/guide-board.png";

const fakeData = [
  { id: 1, data: [5, 5, 0] },
  { id: 2, data: [5, 5, 0] },
  { id: 3, data: [5, 15, 0] },
  { id: 4, data: [10, 15, 0] },
  { id: 5, data: [10, 25, 0] },
  { id: 6, data: [10, 35, 0] },
  { id: 7, data: [15, 0, 0] },
  { id: 8, data: [20, 0, 0] },
  { id: 9, data: [20, 0, 0] },
];

const GuideDetail = () => (
  <Grid width={12} celled className="guide-board-container no-shadow">
    <img src={bgImage} className="guide-board-bg" alt="guide-board-bg" />
    <Grid.Row
      width={12}
      className="no-shadow bg-inner-guide radius-top margin-guide-header"
    >
      <Grid.Column width={4} textAlign="center" className="no-shadow">
        <span style={{ fontSize: 14 }}>Duration Month</span>
      </Grid.Column>
      <Grid.Column width={4} textAlign="center" className="no-shadow">
        <span style={{ fontSize: 14 }}>3 Months Staking</span>
      </Grid.Column>
      <Grid.Column width={4} textAlign="center" className="no-shadow">
        <span style={{ fontSize: 14 }}>6 Months Staking</span>
      </Grid.Column>
      <Grid.Column width={4} textAlign="center" className="no-shadow">
        <span style={{ fontSize: 14 }}>12 Months Staking</span>
      </Grid.Column>
    </Grid.Row>
    <div className="line-guide" />
    {fakeData.map((item, index) => (
      <Grid.Row
        key={item.id.toString()}
        width={12}
        className={`no-shadow bg-inner-guide margin-guide-content ${
          index === fakeData.length - 1
            ? "radius-bottom margin-guide-content-bottom"
            : ""
        }`}
      >
        <Grid.Column
          width={4}
          textAlign="center"
          className={`no-shadow no-padding`}
        >
          <span
            className={`guide-text none-margin-left ${
              index % 2 ? "bg-A" : "bg-B"
            }`}
          >
            {item.id}
          </span>
        </Grid.Column>
        <Grid.Column
          width={4}
          textAlign="center"
          className={`no-shadow no-padding`}
        >
          <span className={`guide-text ${index % 2 ? "bg-A" : "bg-B"}`}>
            {item.data[0]}%
          </span>
        </Grid.Column>
        <Grid.Column
          width={4}
          textAlign="center"
          className={`no-shadow no-padding`}
        >
          <div
            className={`${index % 2 ? "bg-A" : "bg-B"} ${
              index >= 6 ? "guide-lock" : "guide-text"
            }
            ${index === 7 ? "text-lock" : ""}
            `}
          >
            {index < 6
              ? `${item.data[1]}%`
              : index === 7
              ? "Locking Unavailable"
              : "."}
          </div>
        </Grid.Column>
        <Grid.Column
          width={4}
          textAlign="center"
          className={`no-shadow bg-B no-padding`}
        >
          <span className="guide-lock text-lock">
            {index === 4 ? "Locking" : ""}
          </span>
        </Grid.Column>
      </Grid.Row>
    ))}
    {/* <Grid.Row>
      <Grid.Column width={3}>
        <span>1</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <span>3 Months Staking</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <span>6 Months Staking</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <span>12 Months Staking</span>
      </Grid.Column>
    </Grid.Row> */}
  </Grid>
);

export default GuideDetail;
